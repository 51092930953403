import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  
  p {
    font-size: 2.25rem;
    line-height: 1.25;
  }

  @media ${ props => props.theme.device.sm } {
    p {
      font-size: 1.125rem;
      line-height: 1.38;
    }
  }

  a {
    text-decoration: underline;
  }
`

const TextBlock = ({ text }) => {
  return (
    <Container dangerouslySetInnerHTML={{ __html: text }}/>
  )
}

export default TextBlock
