/* eslint-disable max-len */
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import UnstyledVideo from './Video'

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;

  @media ${ props => props.theme.device.sm } {
    width: initial;
    margin-right: -${ props => props.theme.pagePaddingMobile };
    margin-left: -${ props => props.theme.pagePaddingMobile };
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:  center;

  > div {
    margin-right: ${ props => props.theme.pagePadding };
    width: ${ props => 100 / props.length }%;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  @media ${ props => props.theme.device.sm } {
    flex-direction: column;

    > div {
      width: 100%;
      margin-right: 0;
      margin-bottom: ${ props => props.theme.pagePadding };
    }
  }
`

const Video = styled(UnstyledVideo)`
  .light-theme & {
    box-shadow: ${ props => props.shadow && '0 0.7px 0.7px rgba(0, 0, 0, 0.024), 0 2.2px 2.2px rgba(0, 0, 0, 0.036), 0 10px 10px rgba(0, 0, 0, 0.06)' };
  }
`

const VideoBlock = ({ video, sideBySide, sideVideo, shadow }) => {
  const videos = sideBySide ? [video, sideVideo] : [video]
  return (
    <Container length={videos.length}>
      {videos.map((video, index) => {
        return (<Video key={index} video={video} shadow={shadow} />)
      })}
    </Container>
  )
}

export default VideoBlock
