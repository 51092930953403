import React, { Component } from 'react'
import styled from 'styled-components'
import isMobile from '../utils/isMobile'

const Container = styled.div`
  width: 100%;
`

const VideoContainer = styled.div`
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: ${ ({ width, height }) => `${ height / width * 100 }%` };
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`

const PlaceholderImage = styled.img`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
`

class VideoBlock extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      muted: true,
      posterSrc: null
    }

    // this.video = React.createRef()
  }

  // componentDidMount () {
  //   fetch(`https://onelineplayer.glitch.me/vimeo?url=https://vimeo.com/${ this.props.video.providerUid }`)
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data => {
  //       if (!this.video.current) {
  //         return
  //       }

  //       this.setState({
  //         posterSrc: data.poster
  //       })

  //       this.video.current.muted = true
  //       this.video.current.setAttribute('muted', '')
  //       this.video.current.src = getVideoSource(data.video)
  //       this.video.current.addEventListener('timeupdate', this.onVideoLoaded)

  //       this.video.current.play()
  //     })
  // }

  // componentWillUnmount () {
  //   this.video.current.pause()
  //   this.video.current.removeEventListener('timeupdate', this.onVideoLoaded)
  //   this.video.current.removeEventListener('timeupdate', this.onProgress)
  // }

  // onVideoLoaded = () => {
  //   if (this.video.current.currentTime > 0) {
  //     this.video.current.removeEventListener('timeupdate', this.onVideoLoaded)
  //     this.video.current.addEventListener('timeupdate', this.onProgress)
  //     this.setState({
  //       loading: false
  //     })
  //   }
  // }

  render () {
    const { className, video: { width, height, providerUid } } = this.props
    const { loading, posterSrc } = this.state

    return (
      <Container className={className}>
        <VideoContainer width={width} height={height}>
          <iframe
            src={`https://player.vimeo.com/video/${
              providerUid
            }?background=1&autoplay=1&loop=1&autopause=0&dnt=1${
              !isMobile ? '&muted=1' : ''
            }`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"></iframe>
          {loading && <PlaceholderImage src={posterSrc}/>}
        </VideoContainer>
      </Container>
    )
  }
}

function getVideoSource (sources) {
  const sortedResolutions = Object.keys(sources).map(item => parseInt(item.slice(0, -1))).sort((a, b) => b - a)

  const low = sortedResolutions[0]
  const high = sortedResolutions[sortedResolutions.length - 1]

  if (isMobile) {
    return sources['360p'] || sources['240p'] || sources[`${ low }p`]
  } else {
    return sources['1080p'] || sources[`${ high }p`]
  }
}

export default VideoBlock
