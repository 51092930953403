import React, { createRef, Component } from 'react'
import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import ArrowLeftSvg from '../images/svg/arrow-circle-left.svg'
import ArrowRightSvg from '../images/svg/arrow-circle-right.svg'

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  width: initial;
  margin-right: -${ props => props.theme.pagePadding };
  margin-left: -${ props => props.theme.pagePadding };
  position: relative;

  .light-theme & {
    box-shadow: ${ props => props.shadow && `
      0 0.7px 0.7px rgba(0, 0, 0, 0.024),
      0 2.2px 2.2px rgba(0, 0, 0, 0.036),
      0 10px 10px rgba(0, 0, 0, 0.06)
    ` };
  }

  @media ${ props => props.theme.device.sm } {
    margin-right: -${ props => props.theme.pagePaddingMobile };
    margin-left: -${ props => props.theme.pagePaddingMobile };
  }

  .slider,
  .slide {
    -webkit-backface-visibility: hidden !important;
  }

  .slide {
    transform: translate3d(0,0,0) !important;
  }

  .control-arrow:before {
    display: none !important;
    content: none !important;
  }

  .control-arrow {
    padding: 0 !important;
    width: 50% !important;
    -webkit-tap-highlight-color: transparent !important;
    outline: none !important;
    -webkit-backface-visibility: hidden !important;

    ${ props => props.theme.hover } {
      &:hover {
        background: initial !important;
      }
    }
  }

  .control-arrow {
    cursor: none !important;
  }

  @media ${ props => props.theme.device.sm } {
    .control-arrow {
      display: none !important;
    }
  }

  .carousel-slider {
    @media ${ props => props.theme.device.sm }  {
      overflow: initial !important;
    }
  }

  .control-dots {
    display: none !important;
    
    @media ${ props => props.theme.device.sm }  {
      display: block !important;
      margin: 0 !important;
      padding: 10px 0 !important;
      transform: translateY(100%) !important;
    }
  }

  .dot {
    @media ${ props => props.theme.device.sm }  {
     opacity: 1 !important;
      box-shadow: none !important;
      margin: 0 4px !important;
      background-color: ${ props => props.theme.dark.text } !important;

      .light-theme & {
        background-color: ${ props => props.theme.light.text } !important;
      }

      &.selected {
        background-color: ${ props => props.theme.dark.accent } !important;

        .light-theme & {
          background-color: ${ props => props.theme.light.accent } !important;
        }
      }
    }
  }
`

const Arrow = styled.div`
  position: fixed;
  display: none;
  pointer-events: none;
  left: 0;
  top: 0;
  z-index: 2;

  svg {
    .arrow-fill {
      fill: ${ props => props.theme.dark.accent };

      .light-theme & {
        fill: ${ props => props.theme.light.accent };
      }
    }

    .arrow-stroke {
      stroke: ${ props => props.theme.dark.text };

      .light-theme & {
        stroke: ${ props => props.theme.light.text };
      }
    }
  }

  @media ${ props => props.theme.device.sm } {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;

    display: none;
  }
`

const ArrowLeft = styled(Arrow)`
  @media ${ props => props.theme.device.sm } {
    left: 5px;
  }
`

const ArrowRight = styled(Arrow)`
  @media ${ props => props.theme.device.sm } {
    left: initial;
    right: 5px;
  }
`

const Image = styled(GatsbyImage)`
  -webkit-backface-visibility: hidden;    
`

class SlideshowBlock extends Component {
  constructor (props) {
    super(props)

    this.containerRef = createRef()
    this.arrowLeftRef = createRef()
    this.arrowRightRef = createRef()
    this.prevArea = null
    this.nextArea = null
  }

  componentDidMount () {
    this.prevArea = this.containerRef.current.querySelector('.control-arrow.control-prev')
    this.nextArea = this.containerRef.current.querySelector('.control-arrow.control-next')

    this.prevArea.addEventListener('mouseenter', this.showLeftArrow)
    this.prevArea.addEventListener('mouseleave', this.hideLeftArrow)
    this.nextArea.addEventListener('mouseenter', this.showRightArrow)
    this.nextArea.addEventListener('mouseleave', this.hideRightArrow)
    this.prevArea.addEventListener('mousemove', this.moveLeftArrow)
    this.nextArea.addEventListener('mousemove', this.moveRightArrow)
    this.prevArea.addEventListener('mouseover', this.moveLeftArrow)
    this.nextArea.addEventListener('mouseover', this.moveRightArrow)
  }

  componentWillUnmount () {
    this.prevArea.removeEventListener('mouseenter', this.showLeftArrow)
    this.prevArea.removeEventListener('mouseleave', this.hideLeftArrow)
    this.nextArea.removeEventListener('mouseenter', this.showRightArrow)
    this.nextArea.removeEventListener('mouseleave', this.hideRightArrow)
    this.prevArea.removeEventListener('mousemove', this.moveLeftArrow)
    this.nextArea.removeEventListener('mousemove', this.moveRightArrow)
    this.prevArea.removeEventListener('mouseover', this.moveLeftArrow)
    this.nextArea.removeEventListener('mouseover', this.moveRightArrow)
  }

  showLeftArrow = () => {
    this.arrowLeftRef.current.style.display = 'block'
  }

  hideLeftArrow = () => {
    this.arrowLeftRef.current.style.display = 'none'
  }

  showRightArrow = () => {
    this.arrowRightRef.current.style.display = 'block'
  }

  hideRightArrow = () => {
    this.arrowRightRef.current.style.display = 'none'
  }

  moveLeftArrow = ({ clientX, clientY }) => {
    this.arrowLeftRef.current.style.transform = `translate(${ clientX - 20 }px, ${ clientY - 20 }px)`
  }

  moveRightArrow = ({ clientX, clientY }) => {
    this.arrowRightRef.current.style.transform = `translate(${ clientX - 20 }px, ${ clientY - 20 }px)`
  }

  render () {
    const { slides, shadow } = this.props

    return (
      <Container shadow={shadow} ref={this.containerRef}>
        <Carousel
          showThumbs={false}
          showArrows={true}
          infiniteLoop={true}
          swipeable={true}
          showStatus={false}
          showIndicators={true}
        // autoPlay={true}
        // interval={3000}
        >
          {slides.map(({ fluid, format, url }, index) => {
            if (format === 'gif') {
              return <img src={url} key={index}/>
            }

            return <Image key={index} fluid={fluid} loading={'eager'}/>
          })}
        </Carousel>
        <ArrowLeft ref={this.arrowLeftRef}>
          <ArrowLeftSvg/>
        </ArrowLeft>
        <ArrowRight ref={this.arrowRightRef}>
          <ArrowRightSvg/>
        </ArrowRight>
      </Container>
    )
  }
}

export default SlideshowBlock
