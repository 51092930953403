import React, { Fragment } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from './layout'
import SlideshowBlock from '../components/SlideshowBlock'
import ImageBlock from '../components/ImageBlock'
import VideoBlock from '../components/VideoBlock'
import TextBlock from '../components/TextBlock'
import ProjectList from '../components/ProjectList'
import CategoryTagSvg from '../images/svg/categoryTag.svg'

const Container = styled.div`
  margin-top: 2.75rem;

  @media ${ props => props.theme.device.sm } {
    margin-top: 1.25rem;
  }
`

const ProjectName = styled.div`
  margin-top: 0.25rem;
  font-size: 2.5rem;
  line-height: 1.25;

  @media ${ props => props.theme.device.sm }  {
    font-size: 1.25rem;
  }
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  justify-content: flex-end;

  @media ${ props => props.theme.device.lg } {
    flex-direction: column;
  }
`

const RelatedProjects = styled.div`
  flex: 1;

  h1 {
    margin-bottom: 3.75rem;
  }

  @media ${ props => props.theme.device.lg } {
    margin-top: 3.75rem;
    order: 2;
  }
`

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Category = styled(Link)`
  font-size: 2.5rem;
  line-height: 1.25;
  white-space: pre;

  @media ${ props => props.theme.device.sm }  {
    font-size: 1.25rem;
  }
`

const CategoryTag = styled(CategoryTagSvg)`
  margin-right: 8px;

  .category-tag-fill {
    fill: ${ props => props.theme.dark.text };

    .light-theme & {
      fill: ${ props => props.theme.light.text };
    }
  }

  @media ${ props => props.theme.device.lg } {
    margin-right: 4px;
    height: 11px;
  }
`

const ProjectPage = ({
  data: { datoCmsProject: { clientName, projectName, contentBlocks, relatedProjects, category } }
  , location, pageContext
}) => {
  return (
    <Layout location={location} pageContext={pageContext}>
      <Container>
        <h1>{clientName}</h1>
        <ProjectName>{projectName}</ProjectName>
        {contentBlocks.map((contentBlock, index) => {
          let content

          if (contentBlock.slides) {
            content = <SlideshowBlock key={index} {...contentBlock}/>
          }

          if (contentBlock.images) {
            content = <ImageBlock key={index} {...contentBlock}/>
          }

          if (contentBlock.text) {
            content = <TextBlock key={index} {...contentBlock}/>
          }

          if (contentBlock.video) {
            content = <VideoBlock key={index} {...contentBlock}/>
          }

          return content
        })}
        <BottomContainer>
          {relatedProjects && relatedProjects.length > 0 && (
            <RelatedProjects>
              <h1>Related projects:</h1>
              <ProjectList projects={relatedProjects}/>
            </RelatedProjects>
          )}
          <CategoriesContainer>
            {category.map(({ slug, name, displayName }, index) => (
              <Category key={index} to={slug}>
                <CategoryTag/>
                {titleCase(displayName || name)}
              </Category>
            ))}
          </CategoriesContainer>
        </BottomContainer>
      </Container>
    </Layout>
  )
}

function titleCase (string) {
  const splitString = string.toLowerCase().split(' ')
  for (let i = 0; i < splitString.length; i++) {
    // You do not need to check if i is larger than splitString length, as your for does that for you
    // Assign it back to the array
    splitString[i] = splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1)
  }
  // Directly return the joined string
  return splitString.join(' ')
}

export const query = graphql`
  query($originalId: String!) {
    datoCmsProject(originalId: {eq: $originalId}) {
      clientName
      projectName
      displayName
      category {
        name
        displayName
        slug
      }
      relatedProjects {
        clientName
        displayName
        slug
        hoverImage {
          fluid(imgixParams: {
            h: "333",
            w: "500",
            ar: "1.5",
            fm: "jpg",
            auto: "compress",
            fit: "crop",
            crop: "edges"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      contentBlocks {
        ... on DatoCmsImageBlock {
          shadow
          images {
            fluid(maxWidth: 1400, imgixParams: {
              fm: "jpg,gif",
              auto: "compress"
            }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsSlideshowBlock {
          shadow
          slides {
            format
            url
            fluid(maxWidth: 1400, imgixParams: {
              fm: "jpg",
              auto: "compress"
            }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsVideoBlock {
          video {
            width
            height
            providerUid
          }
          shadow
          sideBySide
          sideVideo {
            width
            height
            providerUid
          }
        }
        ... on DatoCmsTextBlock {
          text
        }
      }
    }
  }
`

export default ProjectPage
